import { Accordion, Button, Form } from "react-bootstrap";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import { propertyTree, metaData, skipDisplay } from "../Data/filterData";
import FilterHelptext from "./FilterHelptext";

interface Props {
  setFilterState: Function;
  appliedFilters: Record<string, Record<string, metaData>>;
}

export default function Filters(props: Props) {
  const propertyTreeEntries = Object.entries(propertyTree);

  function selectAll(key: string) {
    const values = Object.entries(propertyTree[key]).map(([value]) => {
      return value;
    });
    props.setFilterState(key, values, true);
  }

  function selectNone(key: string) {
    const values = Object.entries(propertyTree[key]).map(([value]) => {
      return value;
    });
    props.setFilterState(key, values, false);
  }
  return (
    <Accordion>
      {propertyTreeEntries
        .filter(([key, _]) => {
          return !skipDisplay.includes(key);
        })
        .sort()
        .map(([key, values], index) => {
          return (
            <Accordion.Item eventKey={index.toString()} key={index.toString()}>
              <Accordion.Header>{key}</Accordion.Header>
              <AccordionBody>
                <FilterHelptext keyName={key} />
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={() => selectNone(key)}
                >
                  Avmarkera alla
                </Button>{" "}
                <Button
                  className="mx-3"
                  variant="outline-danger"
                  size="sm"
                  onClick={() => selectAll(key)}
                >
                  Markera alla
                </Button>
                <Form className="pt-2">
                  {Object.entries(values)
                    .sort()
                    .map(([value, metaData], index) => {
                      let className = "";
                      let label = value;
                      if (value === "") {
                        label = "Data saknas";
                        className = "data-undefined";
                      }

                      return (
                        <Form.Group key={index}>
                          <Form.Check
                            type="checkbox"
                            id={"checkbox" + key + value}
                          >
                            <Form.Check.Input
                              type="checkbox"
                              checked={props.appliedFilters[key][value].checked}
                              onChange={(event) =>
                                props.setFilterState(
                                  key,
                                  [value],
                                  event.target.checked
                                )
                              }
                            />
                            <Form.Check.Label className={className}>
                              <>
                                {label}{" "}
                                <span className="text-muted">
                                  <>({metaData.count})</>
                                </span>
                              </>
                            </Form.Check.Label>
                          </Form.Check>
                        </Form.Group>
                      );
                    })}
                </Form>
              </AccordionBody>
            </Accordion.Item>
          );
        })}
    </Accordion>
  );
}
