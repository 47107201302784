import Alert from "react-bootstrap/Alert";

type Props = {
  keyName: string;
};

export default function FilterHelptext(props: Props) {
  switch (props.keyName) {
    case "Källor":
      return (
        <Alert variant="warning">
          Om en person har flera källhänvisningar, visas den på kartan om{" "}
          <em>någon</em> av dennes källtyper är ikryssad.
        </Alert>
      );

    case "Övriga sysselsättningar":
      return (
        <Alert variant="warning">
          Om en person har flera övriga sysselsättningar, visas den på kartan om{" "}
          <em>någon</em> av dennes sysselsättningar är ikryssad.
        </Alert>
      );

    default:
      return null;
  }
}
