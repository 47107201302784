import { LatLngExpression } from "leaflet";
import rawData from "./rawData.json";
import { swedish_params, grid_to_geodetic } from "./sweref2wgs84";

export interface IPerson {
  "Härad/skeppslag": string;
  Socken: string;
  By: string;
  Torp: string;
  Nybyggare: string;
  Skattlagd: string;
  Förnamn: string;
  Patronymikon: string;
  Släktnamn: string;
  Tillnamn: string;
  Kön: string;
  Bonde: string;
  Torpare: string;
  "Övrig sysselsättning": string;
  "Övriga sysselsättningar"?: string[];
  Jordnatur: string;
  "x-koordinat": number | string;
  "y-koordinat": number | string;
  "Osäkerhet meter": string | number;
  Källa: string;
  Källor?: string[];
  "Richard Broberg": string;
  "Äldsta belägg": number | string;
  "Sista belägg": number | string;
  "Födelseplats/ursprung": string;
  Övrigt: string | number;
  Coordinates?: LatLngExpression;
}

function filterOutPersonsWithoutCoordinates(person: IPerson) {
  return person["x-koordinat"] > 1 && person["y-koordinat"] > 1;
}

function generateTagsFromString(source: string) {
  const tags: string[] = source.split(",");
  const result: string[] = [];

  return tags.reduce(function (result, tag: string) {
    if (tag.trim() !== "") result.push(tag.trim());
    return result.length !== 0 ? result : [""];
  }, result);
}

swedish_params("sweref_99_tm");

export const personData: Array<IPerson> = rawData
  .filter(filterOutPersonsWithoutCoordinates)
  .map((person: IPerson) => {
    person.Källor = generateTagsFromString(person.Källa);
    person["Övriga sysselsättningar"] = generateTagsFromString(
      person["Övrig sysselsättning"]
    );

    const [x, y] = grid_to_geodetic(
      person["y-koordinat"],
      person["x-koordinat"]
    );

    person.Coordinates = [x, y];
    return person;
  });
