import React from "react";
import Button from "react-bootstrap/Button";
import { useMap } from "react-leaflet";

type HandleShow = {
  handleShowInformationOffcanvas: () => void;
  handleShowFilterOffcanvas: () => void;
};

export function MapControls(props: HandleShow) {
  const map = useMap();
  return (
    <nav id="map-controls" className="position-absolute vstack gap-3">
      <Button onClick={props.handleShowInformationOffcanvas}>
        Information
      </Button>
      <Button onClick={props.handleShowFilterOffcanvas}>Filtrera</Button>
      <Button onClick={() => map.zoomIn()}>Zooma in +</Button>
      <Button onClick={() => map.zoomOut()}>Zooma ut -</Button>
    </nav>
  );
}
