import { CircleMarker, Popup } from "react-leaflet";
import { skipDisplay } from "../Data/filterData";
import { IPerson } from "../Data/personData";

type Props = {
  person: IPerson;
};

export default function Marker(props: Props) {
  let color = "black";
  let radius = 5;

  if (typeof props.person["Osäkerhet meter"] === "number") {
    color = props.person["Osäkerhet meter"] > 1000 ? "#d63384" : "#6610f2";
  }

  if (props.person.Coordinates)
    return (
      <CircleMarker
        center={props.person.Coordinates}
        color={color}
        radius={radius}
      >
        <Popup>
          <table className="table table-hover table-sm table-borderless">
            <tbody>
              {Object.entries(props.person).map(([key, value], index) => {
                if (value === "" || skipDisplay.includes(key)) return null;
                return (
                  <tr key={index}>
                    <td>{key}:</td>
                    <td>{value}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Popup>
      </CircleMarker>
    );
  return null;
}
