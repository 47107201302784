import { personData } from "./personData";

export interface metaData {
  count: number;
  checked: boolean;
}

export const dataMissing = "Data saknas";

export const skipFilter = [
  "x-koordinat",
  "y-koordinat",
  "Källa",
  "Övrig sysselsättning",
  "Radnummer",
  "Övrigt",
];

export const treatAsTags = ["Källor", "Övriga sysselsättningar"];

export const skipDisplay = ["x-koordinat", "y-koordinat", "Coordinates"].concat(
  treatAsTags
);

export let propertyTree: Record<string, Record<string, metaData>> = {};

personData.forEach((person) => {
  for (const [key, value] of Object.entries(person)) {
    if (skipFilter.includes(key)) continue;

    if (treatAsTags.includes(key) && Array.isArray(value)) {
      if (value.length === 0) addToPropertyTree(key, "");

      value.forEach((tag: string) => {
        addToPropertyTree(key, tag);
      });
    } else {
      addToPropertyTree(key, value);
    }
  }
});

const propertyTreeInitialState: Record<
  string,
  Record<string, metaData>
> = structuredClone(propertyTree);

function addToPropertyTree(key: string, value: string) {
  if (!propertyTree[key]) {
    propertyTree[key] = {};
  }
  if (!propertyTree[key][value]) {
    propertyTree[key][value] = {
      count: 1,
      checked: true,
    };
  } else {
    propertyTree[key][value].count += 1;
  }
}

export function resetPropertyTree() {
  propertyTree = structuredClone(propertyTreeInitialState);
}
