import React, { useRef, useState } from "react";
import "./App.css";
import "leaflet/dist/leaflet.css";

import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  LayersControl,
  MapContainer,
  ScaleControl,
  TileLayer,
  useMap,
} from "react-leaflet";
import { MapControls } from "./Components/MapControls";

import { personData } from "./Data/personData";
import MarkerCluster from "./Components/MarkerCluster";
import Filters from "./Components/Filters";
import {
  propertyTree,
  metaData,
  skipFilter,
  treatAsTags,
  resetPropertyTree,
} from "./Data/filterData";
import { thunderstormKey } from "./thunderstormKey";
import { Accordion, Table } from "react-bootstrap";

interface OffcanvasProps {
  show: boolean;
  handleClose: () => void;
}

interface InformationOffcanvasProps extends OffcanvasProps {
  handleToggleToFilters: () => void;
}

function InformationOffcanvas({
  show,
  handleClose,
  handleToggleToFilters,
  ...props
}: InformationOffcanvasProps) {
  const ToMapButtons = () => {
    return (
      <div className="d-flex justify-content-between offcanvas-bottom-buttons">
        <Button onClick={handleClose} size="lg">
          Till kartan
        </Button>{" "}
        <Button
          variant="outline-primary"
          onClick={handleToggleToFilters}
          size="lg"
        >
          Gå direkt till filter
        </Button>
      </div>
    );
  };
  return (
    <Offcanvas show={show} onHide={handleClose} placement={"end"} {...props}>
      <Offcanvas.Header closeButton>
        <h1>Immigranter i Uppland</h1>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Accordion defaultActiveKey={["0"]}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h2 className="h5">Information</h2>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Denna karta presenterar data från Gunnar Larssons forskning om{" "}
                <em>Immgranter i Uppland</em>, främst under 1600-talet.
              </p>
              <p>
                Persondata uppdaterat: 2023-11-20 14:00
                <br />
                Funktioner uppdaterade: 2023-11-20 14:00
              </p>
              <p>Kartan presenterar data för {personData.length} personer</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h2 className="h5">Så fungerar kartan</h2>
            </Accordion.Header>
            <Accordion.Body>
              <p>Tryck på en person för att se mer information om denne.</p>
              <Table>
                <thead>
                  <tr>
                    <td>Symbol</td>
                    <td>Förklaring</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span
                        style={{
                          backgroundColor: "rgba(var(--bs-dark-rgb), 0.9)",
                          color: "var(--bs-light)",
                          borderRadius: "30%",
                          border: "solid rgba(var(--bs-dark-rgb), 1) 2px",
                          fontWeight: 600,
                        }}
                      >
                        10
                      </span>
                    </td>
                    <td>
                      En gruppering med <em>10</em> personer
                    </td>
                  </tr>
                  <tr></tr>
                  <tr>
                    <td>
                      <span style={{ color: "black", fontWeight: 900 }}>⭘</span>
                    </td>
                    <td>Person med upp till 100 meters felmarginal</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ color: "#6610f2", fontWeight: 900 }}>
                        ⭘
                      </span>
                    </td>
                    <td>Person med upp till 1000 meters felmarginal</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ color: "#d63384", fontWeight: 900 }}>
                        ⭘
                      </span>
                    </td>
                    <td>Person med mer än 1000 meters felmarginal</td>
                  </tr>
                </tbody>
              </Table>
              <p>I nedre vänstra hörnet visas aktuell skala.</p>
              <p>I nedre högra hörnet kan du växla bakgrundskarta.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <h2 className="h5">Ladda ner datan</h2>
            </Accordion.Header>
            <Accordion.Body>
              {" "}
              <p>Information om upphovsrätt och användningsvillkor</p>
              <p>
                <a href="https://kartan.forngren.se/">
                  Länk för att ladda ner datan
                </a>
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Offcanvas.Body>
      <ToMapButtons />
    </Offcanvas>
  );
}

interface FilterOffcanvasProps extends OffcanvasProps {
  setFilterState: Function;
  numberOfMarkers: number;
  markerCluster: {
    current: any;
  };
  resetFilters: () => void;
  appliedFilters: Record<string, Record<string, metaData>>;
}

function FilterOffcanvas(props: FilterOffcanvasProps) {
  const map = useMap();
  return (
    <Offcanvas show={props.show} onHide={props.handleClose} placement={"end"}>
      <Offcanvas.Header closeButton>
        <h2>Filtrera</h2>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Filters
          setFilterState={props.setFilterState}
          appliedFilters={props.appliedFilters}
        />
      </Offcanvas.Body>
      <div className="d-flex justify-content-between offcanvas-bottom-buttons">
        {props.markerCluster !== null &&
          props.markerCluster.current !== null && (
            <Button
              disabled={props.numberOfMarkers === 0}
              onClick={() => {
                map.fitBounds(props.markerCluster.current.getBounds());
                props.handleClose();
              }}
            >
              Zooma till {props.numberOfMarkers} personer
            </Button>
          )}
        <Button
          variant="danger"
          onClick={() => props.resetFilters()}
          disabled={props.numberOfMarkers === personData.length}
        >
          Nollställ filter
        </Button>
      </div>
    </Offcanvas>
  );
}

function App() {
  const isElectron =
    navigator.userAgent.toLowerCase().indexOf(" electron/") > -1;

  const [showInformationOffcanvas, setShowInformationOffcanvas] = useState(
    !isElectron
  );
  const [showFilterOffcanvas, setShowFilterOffcanvas] = useState(false);
  const [filteredPersonData, setFilteredPersonData] = useState(personData);
  const [appliedFilters, setAppliedFilters] = useState(propertyTree);

  const handleShowInformationOffcanvas = () =>
    setShowInformationOffcanvas(true);
  const handleCloseInformationOffcanvas = () =>
    setShowInformationOffcanvas(false);
  const handleShowFilterOffcanvas = () => setShowFilterOffcanvas(true);
  const handleCloseFilterOffcanvas = () => setShowFilterOffcanvas(false);

  /**
   * Applies current selected filters to PersonData
   */
  function updateFilteredPersonData(
    appliedFiltersWithoutWaitingForStateChange: Record<
      string,
      Record<string, metaData>
    >
  ) {
    function tagIsChecked(key: string, tags: string[]) {
      return tags.some((tag) => {
        return appliedFiltersWithoutWaitingForStateChange[key][tag].checked;
      });
    }
    setFilteredPersonData(
      personData.filter((person) => {
        for (const [key, value] of Object.entries(person)) {
          if (skipFilter.includes(key)) continue;

          if (treatAsTags.includes(key)) {
            if (!tagIsChecked(key, value)) return false;
          } else {
            if (!appliedFiltersWithoutWaitingForStateChange[key][value].checked)
              return false;
          }
        }
        return true;
      })
    );
  }

  function resetFilteredPersonData() {
    setFilteredPersonData(personData);
  }

  function setFilterState(key: string, values: string[], checked: boolean) {
    let mutablePropertyTree = structuredClone(appliedFilters);
    values.forEach((value) => {
      mutablePropertyTree[key][value].checked = checked;
    });
    setAppliedFilters(mutablePropertyTree);
    updateFilteredPersonData(mutablePropertyTree);
  }

  function resetFilters() {
    resetPropertyTree();
    setAppliedFilters(propertyTree);
    resetFilteredPersonData();
  }

  const markerCluster = useRef();

  return (
    <div id="app">
      <InformationOffcanvas
        show={showInformationOffcanvas}
        handleClose={handleCloseInformationOffcanvas}
        handleToggleToFilters={() => {
          handleCloseInformationOffcanvas();
          handleShowFilterOffcanvas();
        }}
      />
      <MapContainer
        center={[59.8641, 17.6358]}
        zoom={9}
        id="map"
        zoomControl={false}
      >
        <ScaleControl position="bottomleft" />
        <MapControls
          handleShowInformationOffcanvas={handleShowInformationOffcanvas}
          handleShowFilterOffcanvas={handleShowFilterOffcanvas}
        />
        <LayersControl position="bottomright" collapsed={false}>
          <LayersControl.BaseLayer name="OpenStreetMap" checked>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              maxZoom={17}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Landscape">
            <TileLayer
              attribution='<div class="leaflet-control-attribution leaflet-control">Maps &copy; <a href="https://www.thunderforest.com/maps/landscape/">Thunderforest Landscape</a>, Data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap contributors</a></div>'
              url={`https://tile.thunderforest.com/landscape/{z}/{x}/{y}.png?apikey=${thunderstormKey}`}
              maxZoom={17}
            />
          </LayersControl.BaseLayer>{" "}
          <LayersControl.BaseLayer name="Outdoors">
            <TileLayer
              attribution='<div class="leaflet-control-attribution leaflet-control">Maps &copy; <a href="https://www.thunderforest.com/maps/outdoors/">Thunderforest Outdoors</a>, Data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap contributors</a></div>'
              url={`https://tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=${thunderstormKey}`}
              maxZoom={17}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="OpenTopoMap">
            <TileLayer
              url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
              maxZoom={17}
              attribution='Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="4UMaps">
            <TileLayer
              url="./tiles/{z}/{x}/{y}.png"
              maxZoom={12}
              attribution='© <a href="https://www.4umaps.com/">4UMaps.com</a> Map data: <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
          </LayersControl.BaseLayer>
        </LayersControl>
        <MarkerCluster json={filteredPersonData} ref={markerCluster} />
        <FilterOffcanvas
          show={showFilterOffcanvas}
          handleClose={handleCloseFilterOffcanvas}
          setFilterState={setFilterState}
          numberOfMarkers={filteredPersonData.length}
          markerCluster={markerCluster}
          resetFilters={resetFilters}
          appliedFilters={appliedFilters}
        />
      </MapContainer>
    </div>
  );
}

export default App;
