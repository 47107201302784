import L from "leaflet";
import React from "react";
import MarkerClusterGroup from "react-leaflet-cluster";
import { IPerson } from "../Data/personData";
import Marker from "./Marker";

type Props = {
  json: IPerson[];
};

const MarkerCluster = React.forwardRef((props: Props, ref) => {
  const baseSize = 15;
  const maxSize = 30;
  function iconCreateFunction(cluster: { getChildCount: () => number }) {
    var childCount = cluster.getChildCount();

    let size = childCount * 0.2 + baseSize;

    if (size > maxSize) size = maxSize;

    return new L.DivIcon({
      html: childCount.toString(),
      className: "marker-cluster",
      iconSize: new L.Point(size, size),
    });
  }

  return (
    <MarkerClusterGroup
      iconCreateFunction={iconCreateFunction}
      ref={ref}
      maxClusterRadius={(baseSize + maxSize) / 2}
      showCoverageOnHover={false}
    >
      {props.json.map((person, index) => {
        return <Marker person={person} key={index} />;
      })}
    </MarkerClusterGroup>
  );
});

export default MarkerCluster;
